import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import { TittleCustom } from '../../components/TittleCustom';
import { ButtonDeleteCustom } from '../../components/ButtonDeleteCustom';
import { ButtonQueryCustom } from '../../components/ButtonQueryCustom';
import { CheckBoxAgendaCustom } from '../../components/CheckBoxAgendaCustom';
import api_app from '../../apis/api_app';
import { store } from '../../redux/store';
import { Link, useParams } from 'react-router-dom';
import { ButtonDeleteAgendaCustom } from '../../components/ButtonDeleteAgendaCustom';
import moment from 'moment-timezone';
import { agendaModel } from "../../resource/request";
import PatientCard from '../../components/CardCustom';

interface RouteParams {
    id: string;
    [key: string]: string | undefined;
}

const AgendaProfissional: React.FC = () => {
    const [adicionarModalVisible, setAdicionarModalVisible] = useState(false);
    const [eventos, setEventos] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [consultModalVisible, setConsultModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [agendaData, setAgendaData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [duration, setDuration] = useState('');
    const { id } = useParams<RouteParams>();
    const [modalVisible, setModalVisible] = useState(false);
    const [eventDetails, setEventDetails] = useState<any>(null);
    const today = new Date().toISOString().split('T')[0]; // Data no formato YYYY-MM-DD
    const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);

    useEffect(() => {
        moment.tz.setDefault('America/Sao_Paulo'); // Replace with your preferred timezone

        if (adicionarModalVisible) {
            const now = moment().tz('America/Sao_Paulo');
            const today = now.format('YYYY-MM-DD');
            const timeNow = now.format('HH:mm');

            setStartDate(today);
            setEndDate(today);
            setStartTime(timeNow);

            const newTime = now.add(30, 'minutes');
            const newEndTime = newTime.format('HH:mm');

            setEndTime(newEndTime);
            setDuration('00:30');
        }
    }, [adicionarModalVisible]);

    useEffect(() => {
        fetchData();
    }, [id]);

    const convertMySQLTimeToMoment = (timeString: any) => {
        // Parse the time as UTC to prevent unwanted timezone adjustments
        const timeMoment = moment.utc(timeString, 'HH:mm:ss');

        // If you need to convert this time to a specific timezone, you can use tz()
        // For example, to convert to 'America/Sao_Paulo':
        const adjustedTime = timeMoment.tz('America/Sao_Paulo');

        return adjustedTime;
    };

    const fetchData = async () => {
        try {
            // Fetch the agenda data
            const responseAgenda = await api_app.getAgendaProfissional(store.getState().user.user.id || 0);

            // Filter and map the agenda data to events
            const newEventos = responseAgenda.data
                .filter((afg: agendaModel) => afg?.flg_visivel === 'S' && afg.flg_status === 'A')
                .map((ag: any) => {
                    const start = moment(ag.dia, 'YYYY-MM-DD').startOf('day');
                    const horario2 = moment.tz(ag.horario, 'HH:mm:ss').startOf('minute');
                    const horario = moment.tz(ag.horario, 'HH:mm:ss').startOf('hour');
                    start.set({
                        hour: horario.get('hour'),
                        minute: horario2.get('minute')
                    });
                    const end = new Date(start.valueOf() + 30 * 60000);
                    return {
                        title: ag?.tipo_agenda?.tipo_agenda || "-",
                        start: start.toISOString(),
                        end: end.toISOString(),
                        id: ag.id,
                        status: ag?.status_agenda?.status_agenda,
                        status_id: ag?.status_agenda_id,
                        paciente: ag.pessoa_agenda_paciente_idTopessoa?.nome,
                        pacienteId: ag.pessoa_agenda_paciente_idTopessoa?.id
                    };
                });

            // Update the events state with the new events
            setEventos(prevEventos => [...prevEventos, ...newEventos]);

            //console.log('Agenda obtida com sucesso:', newEventos);
        } catch (error) {
            console.error('Erro ao buscar agenda:', error);
        }
    };

    const plugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, multiMonthPlugin];

    const handleSalvarEvento = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();



        const formData = new FormData(e.currentTarget);
        // console.log(formData.get('start') as string);
        // return;
        const startDate = formData.get('start') as string;
        const endDate = formData.get('end') as string;
        const startTime = formData.get('startTime') as string;
        const endTime = formData.get('endTime') as string;
        const duration = formData.get('duration') as string;

        // Criar a data atual no timezone de São Paulo
        const today = moment().format('YYYY-MM-DD');
        const startFormatted = moment(startDate).format('YYYY-MM-DD');
        const endFormatted = moment(endDate).format('YYYY-MM-DD');


        // Converter a string `today` para o formato 'YYYY-MM-DD' para comparação
        const todayFormatted = today.split('/').reverse().join('-');

        if (startDate < todayFormatted) {
            alert('A data de início não pode ser anterior a hoje.');
            return;
        }

        if (endDate < startDate) {
            alert('A data de término não pode ser anterior à data de início.');
            return;
        }

        const monday = formData.getAll('monday').includes('01');
        const tuesday = formData.getAll('tuesday').includes('02');
        const wednesday = formData.getAll('wednesday').includes('03');
        const thursday = formData.getAll('thursday').includes('04');
        const friday = formData.getAll('friday').includes('05');
        const saturday = formData.getAll('saturday').includes('06');
        const sunday = formData.getAll('sunday').includes('07');
        const week = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];

        const newEventos = createEvents(startDate, endDate, startTime, endTime, duration, week);
        setEventos([...eventos, ...newEventos]);
        setAdicionarModalVisible(false);



        try {
            for (const a of newEventos) {
                const timeZone = 'America/Sao_Paulo';
                const date = moment.tz(timeZone);
                const hora = moment( a.horario, "YYYY-MM-DD HH:mm").tz(timeZone);




                const data = {
                    dia: moment( a.start, "YYYY-MM-DD").startOf("day").toISOString(),
                    horario: "2024-09-05T"+moment( a.horario, "YYYY-MM-DD HH:mm").subtract(3, "hours").tz(timeZone).format("HH:mm")+":00.000Z" ,
                    profissional_id: a.profissional_id,
                    status_agenda_id: 1,
                    dat_criado_em: date.toISOString(true),
                    criada_por: a.profissional_id,
                }
                 await api_app.postAgendas(data);

            }

            window.location.reload();
        } catch (error) {
            console.error('Erro ao buscar agendas:', error);
        }
    };

    const countConsultasPorDia = (eventos: any[], selectedDate: Date | null): number => {
        if (!selectedDate) return 0;

        const selectedDateStart = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const selectedDateEnd = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);

        return eventos.filter(evento => {
            const eventDate = new Date(evento.start);
            return eventDate >= selectedDateStart && eventDate < selectedDateEnd;
        }).length;
    };

    const createEvents = (startDate: string, endDate: string, startTime: string, endTime: string, duration: string, week: boolean[]): any[] => {
        const newEventos = [];
        let currentDate = moment.tz(startDate, 'America/Sao_Paulo').startOf('day');
        const endDateTime = moment.tz(endDate, 'America/Sao_Paulo').endOf('day')


        if (duration === "00:00") {
            alert("Verifique os dados digitados e tente novamente");
            window.location.reload();
            return [];
        } else {
            while (currentDate.isSameOrBefore(endDateTime, 'day')) {
                const dayOfWeek = currentDate.day(); // Get day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
                const dayIsChecked = week[dayOfWeek];

                if (dayIsChecked) {
                    let currentStartTime = moment.tz(`${currentDate.format('YYYY-MM-DD')}T${startTime}:00`, 'America/Sao_Paulo');
                    const endOfDayTime = moment.tz(`${currentDate.format('YYYY-MM-DD')}T${endTime}:00`, 'America/Sao_Paulo');
                    const profissional_id = store.getState().user.user.id;

                    while (currentStartTime.isBefore(endOfDayTime)) {
                        const [hours, minutes] = duration.split(':').map(Number);
                        const endDateTime = currentStartTime.clone().add(hours, 'hours').add(minutes, 'minutes');

                        if (endDateTime.isAfter(endOfDayTime)) {
                            break;
                        }


                        const horario2 = moment.tz(currentStartTime, 'HH:mm').startOf('minute');
                        const horario = moment.tz(currentStartTime, 'HH:mm').startOf('hour');
                        currentStartTime.set({
                            hour: horario.get('hour'),
                            minute: horario2.get('minute')
                        });

                        const novoEvento = {
                            title: 'Consulta',
                            start: currentStartTime.toISOString(),
                            end: endDateTime.toISOString(),
                            duration: duration,
                            dia: currentDate.format('YYYY-MM-DD'),
                            horario:currentStartTime,
                            profissional_id: profissional_id,
                            status: '- Livre',
                            paciente: '- Livre',
                            pacienteId: null
                        };
                        newEventos.push(novoEvento);
                        currentStartTime = endDateTime.clone().add(1, 'seconds');
                    }
                }
                currentDate.add(1, 'day');
            }
        }
        return newEventos;
    };

    const handleDateSelect = (selectInfo: any) => {
        setSelectedDate(selectInfo.start);
        const eventsOnSelectedDate = eventos.filter(
            (evento) =>
                new Date(evento.start).getDate() === selectInfo.start.getDate() &&
                new Date(evento.start).getMonth() === selectInfo.start.getMonth() &&
                new Date(evento.start).getFullYear() === selectInfo.start.getFullYear()
        );
        setSelectedEvent(eventsOnSelectedDate);
        setConsultModalVisible(true);
    };

    const customEventContent = (eventInfo: any) => {
        const viewType = eventInfo.view.type;
        const isMonthView = viewType === 'dayGridMonth';
        const isListView = viewType === 'listWeek' || viewType === 'listDay';
        const isYearView = viewType === 'multiMonthYear';
        const isTimeGridWeek = viewType === 'timeGridWeek';
        const isTimeGridDay = viewType === 'timeGridDay';


        const statusColors: { [key: number]: string } = {
            3: '#7ED957',
            4: '#FF914D',
            5: '#0CC0DF',
            1: '#D9D9D9',
            2: '#FFDE59',
        };

        const statusColor = statusColors[eventInfo.event.extendedProps.status_id] || '#D9D9D9';

        const eventContentStyle: React.CSSProperties = {
            display: 'flex',
            alignItems: 'center',
            padding: '2px 4px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        };

        const statusBulletStyle: React.CSSProperties = {
            backgroundColor: statusColor,
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '6px',
        };

        const mainContentStyle: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };
        const formattedTime = isListView
            ? eventInfo.timeText
            : moment(eventInfo.timeText, 'HH:mm').format('HH:mm');

        return (
            <div style={eventContentStyle}>
                {(isMonthView || isListView || isTimeGridWeek || isTimeGridDay) && (
                    <div style={statusBulletStyle} />
                )}
                <div style={mainContentStyle}>
                    <div>{formattedTime}</div>
                    <span>&nbsp;</span>
                    {(isMonthView  || isTimeGridWeek || isTimeGridDay) && (
                    <div>{eventInfo.event.extendedProps.paciente || eventInfo.event.extendedProps.status}</div>
                    )}

                    {(isListView) && (
                        <>
                            <div>{ eventInfo.event.extendedProps.status}</div>
                            {(eventInfo.event.extendedProps.paciente) && (
                                <>
                                    <div>&nbsp; - &nbsp;</div>
                                    <div>{eventInfo.event.extendedProps.paciente || ''}</div>
                                </>
                            )}
                        </>
                    )}

                </div>
            </div>
        );
    };

    useEffect(() => {
        const updateDots = () => {
            const dotElements = document.querySelectorAll('.fc-list-event-dot') as NodeListOf<HTMLElement>;
            dotElements.forEach((dot) => {
                dot.style.display = 'none';
            });
        };

        updateDots();

        const observer = new MutationObserver(() => {
            updateDots();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    const handleEventClick = (info: any) => {
        console.log(info);
        setEventDetails({
            id: info.event.id,
            title: info.event.title,
            start: info.event.start,
            status: info.event.extendedProps.status_id,
            paciente: info.event.extendedProps.paciente
        });
        setSelectedPatientId(info.event.extendedProps.pacienteId);
        setModalVisible(true);
    };

    return (
        <>
            <div className="flex items-center justify-between rounded-t mb-6 dark:border-gray-600">
                <TittleCustom name="Agenda Profissional" />
                <button
                    className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white focus:ring-4 rounded-lg"
                    style={{ backgroundColor: '#3b5179' }}
                    onClick={() => setAdicionarModalVisible(true)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    Nova Agenda
                </button>
            </div>

            <FullCalendar
                plugins={plugins}
                initialView="dayGridMonth"
                locale={ptLocale}

                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listWeek',
                }}
                events={eventos}
                eventContent={customEventContent}
                editable={true}
                selectable={true}
                select={handleDateSelect}
                dayMaxEventRows={true}
                dayMaxEvents={true}
                eventClick={handleEventClick}
            />

            <Modal
                title="Agendamento de Consulta"
                visible={adicionarModalVisible}
                onCancel={() => setAdicionarModalVisible(false)}
                footer={null}
            >
                <form onSubmit={handleSalvarEvento}>
                    <div className="grid gap-4 grid-cols-2">
                        <div>
                            <label className="block mb-1">Período Inicial:</label>
                            <input
                                type="date"
                                name="start"
                                value={startDate}
                                min={moment( today, "YYYY-MM=DD").startOf("day").format("YYYY-MM=DD")}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Período Inicial"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Período Final:</label>
                            <input
                                type="date"
                                name="end"
                                value={endDate}
                                min={ moment( startDate, "YYYY-MM=DD").startOf("day").format("YYYY-MM=DD")}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Período Final"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Hora de Início:</label>
                            <input
                                type="time"
                                name="startTime"
                                value={startTime}
                                min={endDate && startDate === endDate ? '00:00' : startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Hora de Início"
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-1">Hora Final:</label>
                            <input
                                type="time"
                                name="endTime"
                                value={endTime}
                                min={startTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                placeholder="Hora Final"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                            />
                        </div>
                    </div>
                    <legend className="mb-4">Dias da semana:</legend>
                    <div className="grid grid-cols-2 gap-4">

                        <fieldset className="max-w-md">
                            <CheckBoxAgendaCustom
                                label='Segunda-feira'
                                value='01'
                                name='monday'
                            />
                            <CheckBoxAgendaCustom
                                label='Terça-feira'
                                value='02'
                                name='tuesday'
                            />
                            <CheckBoxAgendaCustom
                                label='Quarta-feira'
                                value='03'
                                name='wednesday'
                            />
                            <CheckBoxAgendaCustom
                                label='Quinta-feira'
                                value='04'
                                name='thursday'
                            />
                        </fieldset>

                        <fieldset className="max-w-md">
                            <CheckBoxAgendaCustom
                                label='Sexta-feira'
                                value='05'
                                name='friday'
                            />
                            <CheckBoxAgendaCustom
                                label='Sábado'
                                value='06'
                                name='saturday'
                            />
                            <CheckBoxAgendaCustom
                                label='Domingo'
                                value='07'
                                name='sunday'
                            />
                        </fieldset>

                    </div>
                    <div className="mb-6">
                        <label className="block mb-1">Tempo de Consulta:</label>
                        <input
                            type="time"
                            name="duration"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Tempo de consulta"
                            required
                        />
                    </div>
                    <div className="flex justify-end mt-6">
                        <button
                            type="submit"
                            className="py-2.5 px-5 text-sm font-medium text-white focus:ring-4 rounded-lg"
                            style={{ backgroundColor: '#a7eb51' }}
                        >
                            Salvar
                        </button>
                    </div>
                </form>

            </Modal>

            <Modal
                title={`Eventos do Dia - ${selectedDate ? selectedDate.toLocaleDateString('pt-BR') : ''}`}
                visible={consultModalVisible}
                onCancel={() => {
                    setConsultModalVisible(false);
                    setSelectedDate(null);
                }}
                footer={null}
                style={{ minWidth: '600px', maxWidth: '90%', margin: '20px auto' }}
            >
                {selectedEvent && (
                    <div className="mb-4">
                        <div className="border-b mb-4 justify-between items-center">
                            <div>
                                <table
                                    className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-3">
                                    <thead
                                        className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Horário
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Status
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Paciente
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedEvent.map((event: any, index: number) => (
                                            <tr key={index}
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                                                <td scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {
                                                        // new Date(event.start).toLocaleTimeString('pt-BR')
                                                        moment(event.start).format('HH:mm')
                                                    }
                                                </td>
                                                <td scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {event.status || "- Livre"}
                                                </td>
                                                <td scope="row"
                                                    className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {event.paciente || "- Livre"}
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <p className='mb-3'>Total de
                                    consultas: {countConsultasPorDia(selectedEvent, selectedDate)}</p>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>

            <Modal
                title="Detalhes da Agenda"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                destroyOnClose={true}
            >
                {eventDetails && (
                    <div>
                        <div className='mb-6'>
                            <p className='mb-1'><strong>Data:</strong> {
                                eventDetails.start ? moment(eventDetails.start).format('DD/MM/YYYY') : ''
                            }</p>
                            <div>
                                {selectedPatientId !== null && (
                                    <div className="agenda-content">
                                        <PatientCard id={selectedPatientId} />
                                    </div>

                                )}
                            </div>
                        </div>
                        <ButtonDeleteAgendaCustom link={"/agenda/" + eventDetails.id} />
                        {(eventDetails.paciente && eventDetails.status !== 5) && (
                            <Link
                                to={'/dados-profissionais/agenda/atendimento-consulta/' + eventDetails.id}
                                type="button"
                                className="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-900 dark:hover:bg-blue-800 focus:outline-none dark:focus:ring-blue-900"
                                style={{ backgroundColor: '#598c89' }}
                            >
                                Ir Para Consulta - {eventDetails.status }
                            </Link>)}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default AgendaProfissional;
