import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FooterCustom } from './Footer';
import { store } from "../redux/store";
import api_app from "../apis/api_app";
import { createUser } from "../redux/user/slice";
import { useKeycloak } from "@react-keycloak/web";
import { keycloak, loadUserInfo, hasGroup } from "../keycloak";
import { useDispatch } from "react-redux";
import SpinnerCustom from "../components/SpinnerCustom";
import moment from "moment-timezone";

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isNav, setIsNav] = useState(true);
    const [profissional, setProfissional] = useState(false);
    const [paciente, setPaciente] = useState(false);
    const [desenvolvedor, setDesenvolvedor] = useState(false);

    let navigate = useNavigate();
    let dispach = useDispatch();

    useEffect(() => {
        // Set default timezone for the whole app
        moment.tz.setDefault('America/Sao_Paulo'); // Replace with your preferred timezone
    }, []);



    useEffect(() => {

        if (keycloak.authenticated && store.getState().user.user.id === undefined){
            window.location.reload();
        }
        keycloak.onAuthSuccess = function () {
            api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
                if (result) {

                    setProfissional(hasGroup("/profissional") || false);
                    setPaciente(hasGroup("/paciente") || false);
                    setDesenvolvedor(hasGroup("/desenvolvedor") || false);

                    dispach(createUser(result));
                } else {
                    navigate('/registro');
                }
            });
        }

    }, []);

    const [
        showDadosPessoais, setShowDadosPessoais,
    ] = useState(false);

    const [
        showDadosProfissionais, setShowDadosProfissionais,
    ] = useState(false);

    const [
        showSecretariado, setShowSecretariados,
    ] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        setShowDadosPessoais(false);
    };

    const toggleDadosPessoais = () => {
        setShowDadosPessoais(!showDadosPessoais);
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const toggleDadosProfissionais = () => {
        setShowDadosProfissionais(!showDadosProfissionais);
        if (!isOpen) {
            setIsOpen(true);
        }
    };


    const toggleDadosSecretariado = () => {
        setShowSecretariados(!showSecretariado);
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const closeSidebar = () => {
        setIsOpen(false);
        setShowDadosPessoais(false);
        setShowDadosProfissionais(false);
    };

    if (!profissional && !paciente && !desenvolvedor) {
        return <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
            <SpinnerCustom />
        </div>;
    }

    return (
        <>

            {isNav && (
                <div>
                    <aside
                        id="drawer-navigation"
                        className={`fixed top-16 left-0 z-40 ${isOpen ? 'w-64' : 'w-16'} h-screen pt-2 transition-width duration-300 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
                        aria-label="Sidebar"
                    >
                        <div className="h-full flex flex-col justify-between px-3 pb-4 overflow-y-auto">
                            <div>
                                <ul className="space-y-2 font-medium">

                                    {/* Dados pessoais */}
                                    <li>
                                        <button
                                            type="button"
                                            className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            onClick={toggleDadosPessoais}
                                            aria-expanded={showDadosPessoais ? 'true' : 'false'}
                                        >
                                            {isOpen || showDadosPessoais ? (
                                                <>
                                                    <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor" viewBox="0 0 18 20">
                                                        <path
                                                            d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
                                                    </svg>
                                                    <span
                                                        className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Meus Dados Pessoais</span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className={`w-6 h-6 ml-2 transform ${showDadosPessoais ? 'rotate-90' : 'rotate-0'} text-gray-800 dark:text-white`}
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                    </svg>
                                                </>
                                            ) : (
                                                <svg
                                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 18 20"
                                                >
                                                    <path
                                                        d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
                                                </svg>
                                            )}
                                        </button>
                                        <ul
                                            id="dados-pessoais"
                                            className={`${showDadosPessoais ? 'block' : 'hidden'} py-2 space-y-2 pl-8`}
                                        >
                                            <li>
                                                <Link
                                                    to="/perfil"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6 mr-2"
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Meu
                                                        Perfil</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/agendamento-de-consulta"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6 mr-2"
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Agendamento
                                                        De Consultas</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/pronto-atendimento"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                        className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Pronto
                                                        Atendimento</p>


                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/prontuario"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                        className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white"> Meu
                                                        Prontuário</p>
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link
                                                    to="/relatorio"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Relatórios</p>
                                                </Link>
                                            </li> */}

                                        </ul>
                                    </li>

                                    {profissional && (
                                        <li>
                                            <button
                                                type="button"
                                                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                                onClick={toggleDadosProfissionais}
                                                aria-expanded={showDadosProfissionais ? 'true' : 'false'}
                                            >
                                                {isOpen || showDadosProfissionais ? (
                                                    <>
                                                        <svg className="w-5 h-5 text-gray-800 dark:text-white"
                                                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                            fill="none" viewBox="0 0 18 20">
                                                            <path stroke="currentColor" strokeLinecap="round"
                                                                strokeLinejoin="round" strokeWidth="2"
                                                                d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                                        </svg>

                                                        <span
                                                            className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Dados Profissionais</span>

                                                        {/* <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Dados Profissionais {profissional ? "ok" : "no"}</span> */}

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className={`w-4 h-5 ml-2 transform ${showDadosProfissionais ? 'rotate-90' : 'rotate-0'} text-gray-800 dark:text-white`}
                                                        >
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                            fill="none" viewBox="0 0 18 20">
                                                            <path stroke="currentColor" strokeLinecap="round"
                                                                strokeLinejoin="round" strokeWidth="2"
                                                                d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                                        </svg>
                                                    </>
                                                )}
                                            </button>
                                            <ul
                                                id="dados-profissionais"
                                                className={`${showDadosProfissionais ? 'block' : 'hidden'} py-2 space-y-2 pl-8`}
                                            >
                                                <li>
                                                    <Link
                                                        to="/dados-profissionais/agenda"
                                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                            className="w-6 h-6 mr-2">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                        </svg>

                                                        <p className="text-base text-gray-900 dark:text-white">Agenda</p>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/dados-profissionais/meus-pacientes"
                                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                                        </svg>


                                                        <p className="text-base text-gray-900 dark:text-white">Meus Pacientes</p>
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"/>
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Pronto
                                                        Atendimento</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                                    </svg>

                                                    <p className="text-base text-gray-900 dark:text-white">Pacientes
                                                        (Prontuários)</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
                                                    </svg>

                                                    <p className="text-base text-gray-900 dark:text-white">Locais De
                                                        Atendimento</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                                    </svg>

                                                    <p className="text-base text-gray-900 dark:text-white">Secretariado</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                                    </svg>
                                                    <p className="text-base text-gray-900 dark:text-white">Relatórios</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                                    </svg>

                                                    <p className="text-base text-gray-900 dark:text-white">Aprovações
                                                        Pendentes</p>
                                                </Link>
                                            </li> */}
                                            </ul>
                                        </li>
                                    )}


                                    {desenvolvedor && (
                                        <li>
                                            <button
                                                type="button"
                                                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                                onClick={toggleDadosSecretariado}
                                                aria-expanded={showSecretariado ? 'true' : 'false'}
                                            >
                                                {isOpen || showSecretariado ? (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5" viewBox="0 0 16 16">
                                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
                                                        </svg>
                                                        <span
                                                            className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Secretariado</span>

                                                        {/* <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Dados Profissionais {profissional ? "ok" : "no"}</span> */}

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className={`w-4 h-5 ml-2 transform ${showSecretariado ? 'rotate-90' : 'rotate-0'} text-gray-800 dark:text-white`}
                                                        >
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                            fill="none" viewBox="0 0 18 20">
                                                            <path stroke="currentColor" strokeLinecap="round"
                                                                strokeLinejoin="round" strokeWidth="2"
                                                                d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                                        </svg>
                                                    </>
                                                )}
                                            </button>
                                            <ul
                                                id="secretariado"
                                                className={`${showSecretariado ? 'block' : 'hidden'} py-2 space-y-2 pl-8`}
                                            >
                                                <li>
                                                    <Link
                                                        to="/planos"
                                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                        </svg>
                                                        <p className="text-base text-gray-900 dark:text-white">Planos SEC24</p>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </aside>

                    <button
                        type="button"
                        onClick={toggleSidebar}
                        aria-controls="drawer-navigation"
                        className={`fixed bottom-4 left-2 z-50 bg-white p-2 rounded-full shadow-lg text-gray-900 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-200 transition duration-300 ${isOpen ? 'hidden' : ''}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>

                    </button>

                    <button
                        type="button"
                        onClick={closeSidebar}
                        aria-label="Fechar Sidebar"
                        className={`fixed bottom-4 left-2 z-50 bg-white p-2 rounded-full shadow-lg text-gray-900 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-200 transition duration-300 ${isOpen ? '' : 'hidden'}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                        </svg>

                    </button>
                </div>
            )}
            <div
                className="flex flex-col min-h-screen transition-all duration-300 bg-white dark:bg-gray-800 dark:text-white">
                <div className={`flex-grow ${isOpen ? 'ml-64' : 'ml-16'} p-4`}>
                    <div className="p-4 border-dashed rounded-lg mt-14 bg-white dark:bg-gray-800 dark:border-gray-700">
                        <Outlet />
                    </div>
                </div>
                <div
                    className={`p-4 border-t border-gray-200 dark:border-gray-700 ${isOpen ? 'ml-64' : 'ml-16'} bg-white dark:bg-gray-800`}>
                    <FooterCustom />
                </div>
            </div>

        </>
    );
};

export default SideBar;